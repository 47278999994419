[
  {
    "numero": 16,
    "titulo": "Adesão em dentes hipomineralizados: Uma Revisão Sistemática Integrativa",
    "autor": "Françoise Kleinsorgen*, Luis Caetano, Orlanda Torres",
    "ficheiro": "P16.jpg"
  },
  {
    "numero": 17,
    "titulo": "The Impact of Various Treatments on the QoL of Burning Mouth Syndrome Patients: A Systematic Review",
    "autor": "Maria Pereira*, João Mendes Abreu, Anabela Quitério, Érica Cerqueira, Tiago Nunes, José Figueiredo, Ana Corte-Real",
    "ficheiro": "P17.jpg"
  },
  {
    "numero": 19,
    "titulo": "Musicoterapia como técnica de controlo comportamental no paciente pediátrico – revisão da literatura",
    "autor": "Inês Faria*, Ana Daniela Soares, Inês Nunes, Ana Margarida Esteves, Ana Luísa Costa, Maria Teresa Xavier",
    "ficheiro": "P19.jpg"
  },
  {
    "numero": 20,
    "titulo": "Potencial efeito de águas com sabor na erosão dentária – revisão da literatura",
    "autor": "Mariana Branco*, Bárbara Cunha, Inês Nunes, Ana Margarida Esteves, Maria Teresa Xavier, Ana Luísa Costa, Ana Daniela Soares",
    "ficheiro": "P20.jpg"
  },
  {
    "numero": 21,
    "titulo": "Citotoxicidade dos alinhadores ortodônticos: State of art",
    "autor": "Beatriz Ornelas*, Catarina Nunes, Carlos Miguel Marto, Anabela Baptista Paula, Mafalda Laranjo, Raquel Travassos, Inês Francisco, Francisco Vale",
    "ficheiro": "P21.jpg"
  },
  {
    "numero": 27,
    "titulo": "Falha primária de erupção dentária e o gene do recetor 1 da hormona da paratiróide",
    "autor": "Joana Pereira*; Sónia A Pereira; Ana Sofia Roseiro",
    "ficheiro": "P27.jpg"
  },
  {
    "numero": 28,
    "titulo": "Effect of endodontic irrigation using sodium hypochlorite on adhesion to coronal dentin – A narrative review",
    "autor": "Elder Fernandes* , Joana A. Marques, Rui Falacho, João Carlos Ramos , Paulo J. Palma",
    "ficheiro": "P28.jpg"
  },
  {
    "numero": 29,
    "titulo": "Comparação de diferentes tipos de cimentos de obturação em Endodontia: Revisão Narrativa.",
    "autor": "Moushir Affaki *, Moaz Alhaddad *, José Martinho, Ana Craveiro , Siri Paulo, Manuel Marques Ferreira",
    "ficheiro": "P29.jpg"
  },
  {
    "numero": 30,
    "titulo": "Relação entre o tratamento ortodôntico com alinhadores e a atividade dos músculos mastigatórios: scoping review",
    "autor": "Joana Francela*, Sónia Alves, Natália Ferreira",
    "ficheiro": "P30.jpg"
  },
  {
    "numero": 31,
    "titulo": "Medicolegal responsibility from the healthcare professional's viewpoint - a scoping review",
    "autor": "Luís, C* ; Ribeiro R , Seoane R , Côrte-Real A",
    "ficheiro": "P31.jpg"
  }
]
