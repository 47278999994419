[
  {
    "numero": 9,
    "titulo": "Influência da expansão maxilar na sintomatologia da apneia obstrutiva do sono",
    "autor": "André Venâncio*, Inês Francisco, Bruno Macedo de Sousa, Catarina Nunes, Raquel Travassos, Francisco do Vale",
    "ficheiro": "P9.jpg"
  },
  {
    "numero": 10,
    "titulo": "Avaliação da citotoxicidade de agentes químicos de remoção de cárie: Estudo in vitro",
    "autor": "Carlos Miguel Marto*, Sara Barreira, Anabela Paula, Inês Amaro, Ana Cristina Gonçalves, Eunice Carrilho, Maria Filomena Botelho, Mafalda Laranjo, Ana Coelho",
    "ficheiro": "P10.jpg"
  },
  {
    "numero": 11,
    "titulo": "Avaliação da prevalência de cárie dentária numa população adulta com obesidade",
    "autor": "Inês Melo*, Inês Amaro, Ana Coelho",
    "ficheiro": "P11.jpg"
  },
  {
    "numero": 12,
    "titulo": "Comportamento óptico de coroas cerâmicas cimentadas sobre substratos escurecidos - estudo in vitro",
    "autor": "Raul Yehudi*; Ricardo Dias; Cristiano Alves",
    "ficheiro": "P12.jpg"
  },
  {
    "numero": 13,
    "titulo": "Efeito de fibras de reforço na resistência à flexão de resinas definitivas impressas: estudo piloto",
    "autor": "Inês Silva*, Francisco Silva, Gabriela Almeida, Bruno Matos, Ricardo Dias, Alexandra Vinagre, João Carlos Ramos",
    "ficheiro": "P13.jpg"
  },
  {
    "numero": 14,
    "titulo": "Avaliação de formas de adesão de dentes de prótese realizados em diferentes materiais a uma estrutura híbrida sobre implantes",
    "autor": "Ricardo Rodrigues*, Ricardo Dias, Cristiano Alves, João Carlos Ramos",
    "ficheiro": "P14.jpg"
  },
  {
    "numero": 15,
    "titulo": "Efeito de fibras de reforço na resistência à flexão de resinas provisórias impressas: estudo piloto",
    "autor": "Francisco Silva*, Inês Silva, Gabriela Almeida, Bruno Matos, Ricardo Dias, Alexandra Vinagre, João Carlos Ramos",
    "ficheiro": "P15.jpg"
  }
]
