[
  {
    "numero": 22,
    "titulo": "Atraso da erupção dentária: Revisão da literatura",
    "autor": "Limary Sacramento Lima*, Ana Sofia de Oliveira Bento Roseiro, Sónia Margarida Alves Pereira",
    "ficheiro": "P22.jpg"
  },
  {
    "numero": 23,
    "titulo": "Obtenção de substratos dentinários “patológicos” para estudos laboratoriais: revisão",
    "autor": "Maria Isabel Dantas Viana*, Gabriela Almeida, João Carlos Ramos, Ana Luísa Costa",
    "ficheiro": "P23.jpg"
  },
  {
    "numero": 24,
    "titulo": "Métodos Ortodônticos para a Correção da Curva de Spee: Scoping review",
    "autor": "Ana Lúcia*, Inês Francisco, Catarina Nunes, Raquel Travassos, Carlos Miguel Marto, Francisco do Vale",
    "ficheiro": "P24.jpg"
  },
  {
    "numero": 25,
    "titulo": "Alterações da Cavidade Oral em pacientes com Doença de Biologia dos telómeros - Foco na Disqueratose Congénita (State of Art)",
    "autor": "Inês Margarida Leal Salgado*, Joana Alexandra Teixeira Azevedo, Ana Bela Sarmento Antunes Cruz Ribeiro, Francisco Joaquim Dias Marques",
    "ficheiro": "P25.jpg"
  },
  {
    "numero": 26,
    "titulo": "ALINHADORES VS APARELHO FIXO: VANTAGENS E DESVANTAGENS. SCOPE REVIEW",
    "autor": "César Eulógio Rocha da Silva*; José Romero Souto de Sousa Júnior",
    "ficheiro": "P26.jpg"
  },
  {
    "numero": 32,
    "titulo": "NÍVEL DE LITERACIA E CONHECIMENTOS EM SAÚDE ORAL DOS PAIS E FATORES ASSOCIADOS: REVISÃO NARRATIVA",
    "autor": "Beatriz Tomé*, Margarida Esteves, Inês Nunes, Bárbara Cunha, Ana Daniela Soares, Sara Rosa, Maria Teresa Xavier, Ana Luísa Costa",
    "ficheiro": "P32.jpg"
  },
  {
    "numero": 33,
    "titulo": "Associação entre Periodontite e Doença de Alzheimer: revisão crítica da literatura",
    "autor": "Pedro Silva*, Miguel Pereira, Orlando Martins, Isabel Baptista",
    "ficheiro": "P33.jpg"
  },
  {
    "numero": 34,
    "titulo": "Título: Mordida Cruzada Posterior em Idade Precoce: Diagnóstico e Tratamento",
    "autor": "Maria Luís Oliveira*, Eduardo Seabra, Francisco Silva, Paula Bebiano, Ana Roseiro, Sónia Alves",
    "ficheiro": "P34.jpg"
  },
  {
    "numero": 35,
    "titulo": "Literacia em saúde oral na gravidez e suas implicações: revisão narrativa",
    "autor": "Inês Bolhaqueiro*, Margarida Esteves, Inês Nunes, Ana Daniela Soares, Maria Teresa Xavier, Bárbara Cunha, Sara Rosa, Ana Luísa Costa",
    "ficheiro": "P35.jpg"
  },
  {
    "numero": 36,
    "titulo": "Abordagem terapêutica de lesões periapicais extensas de origem endodôntica – Revisão bibliográfica",
    "autor": "Mariana Oliveira*, Joana A. Marques , Catarina Chaves, Francisco J. D. Marques, Paulo J. Palma",
    "ficheiro": "P36.jpg"
  }
]
