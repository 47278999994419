[
  {
    "numero": 32,
    "titulo": "NÍVEL DE LITERACIA E CONHECIMENTOS EM SAÚDE ORAL DOS PAIS E FATORES ASSOCIADOS: REVISÃO NARRATIVA",
    "autor": "Beatriz Tomé*, Margarida Esteves, Inês Nunes, Bárbara Cunha, Ana Daniela Soares, Sara Rosa, Maria Teresa Xavier, Ana Luísa Costa",
    "ficheiro": "P32.jpg"
  },
  {
    "numero": 33,
    "titulo": "Associação entre Periodontite e Doença de Alzheimer: revisão crítica da literatura",
    "autor": "Pedro Silva*, Miguel Pereira, Orlando Martins, Isabel Baptista",
    "ficheiro": "P33.jpg"
  },
  {
    "numero": 34,
    "titulo": "Título: Mordida Cruzada Posterior em Idade Precoce: Diagnóstico e Tratamento",
    "autor": "Maria Luís Oliveira*, Eduardo Seabra, Francisco Silva, Paula Bebiano, Ana Roseiro, Sónia Alves",
    "ficheiro": "P34.jpg"
  },
  {
    "numero": 35,
    "titulo": "Literacia em saúde oral na gravidez e suas implicações: revisão narrativa",
    "autor": "Inês Bolhaqueiro*, Margarida Esteves, Inês Nunes, Ana Daniela Soares, Maria Teresa Xavier, Bárbara Cunha, Sara Rosa, Ana Luísa Costa",
    "ficheiro": "P35.jpg"
  },
  {
    "numero": 36,
    "titulo": "Abordagem terapêutica de lesões periapicais extensas de origem endodôntica – Revisão bibliográfica",
    "autor": "Mariana Oliveira*, Joana A. Marques , Catarina Chaves, Francisco J. D. Marques, Paulo J. Palma",
    "ficheiro": "P36.jpg"
  }
]
