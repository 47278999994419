[
  {
    "numero": 27,
    "titulo": "Falha primária de erupção dentária e o gene do recetor 1 da hormona da paratiróide",
    "autor": "Joana Pereira*; Sónia A Pereira; Ana Sofia Roseiro",
    "ficheiro": "P27.jpg"
  },
  {
    "numero": 28,
    "titulo": "Effect of endodontic irrigation using sodium hypochlorite on adhesion to coronal dentin – A narrative review",
    "autor": "Elder Fernandes* , Joana A. Marques, Rui Falacho, João Carlos Ramos , Paulo J. Palma",
    "ficheiro": "P28.jpg"
  },
  {
    "numero": 29,
    "titulo": "Comparação de diferentes tipos de cimentos de obturação em Endodontia: Revisão Narrativa.",
    "autor": "Moushir Affaki *, Moaz Alhaddad *, José Martinho, Ana Craveiro , Siri Paulo, Manuel Marques Ferreira",
    "ficheiro": "P29.jpg"
  },
  {
    "numero": 30,
    "titulo": "Relação entre o tratamento ortodôntico com alinhadores e a atividade dos músculos mastigatórios: scoping review",
    "autor": "Joana Francela*, Sónia Alves, Natália Ferreira",
    "ficheiro": "P30.jpg"
  },
  {
    "numero": 31,
    "titulo": "Medicolegal responsibility from the healthcare professional's viewpoint - a scoping review",
    "autor": "Luís, C* ; Ribeiro R , Seoane R , Côrte-Real A",
    "ficheiro": "P31.jpg"
  }
]
