[
  {
    "numero": 37,
    "titulo": "Injeção intra-articular de Ácido Hialurónico e Plasma Rico em Plaquetas: Caso Clínico",
    "autor": "Mariana Marques Dias*, Bruno Miguel Macedo Sousa, Maria João Pascoal Rodrigues, Nansi Lopez-Valverde Hernandez",
    "ficheiro": "P37.jpg"
  },
  {
    "numero": 38,
    "titulo": "Potencial de cicatrização precoce da técnica MIST com plugs de L-PRF na regeneração periodontal",
    "autor": "Miriam Gomez Aboín*; Sérgio Matos",
    "ficheiro": "P38.jpg"
  },
  {
    "numero": 39,
    "titulo": "Enfisema facial após uso de jato de ar-água-pó na instrumentação subgengival: relato de caso clínico",
    "autor": "Bruno Matos*, João Carlos Ramos, Sérgio Matos",
    "ficheiro": "P39.jpg"
  },
  {
    "numero": 40,
    "titulo": "Complicações graves da utilização de bioestimuladores injetáveis: a propósito de um caso clínico",
    "autor": "Dmitry Shelepenko*; Beatriz Jardim; Bárbara Sousa; Francisco Marques",
    "ficheiro": "P40.jpg"
  },
  {
    "numero": 41,
    "titulo": "PIOESTOMATITE VEGETANTE: UM DESAFIO DIAGNÓSTICO",
    "autor": "Lara Gomes*, Sofia Vieira, Mariana Branco, Orlando Martins, Francisco Marques",
    "ficheiro": "P41.jpg"
  },
  {
    "numero": 42,
    "titulo": "Histiocitose de Células não Langerhans: a importância da abordagem multidisciplinar",
    "autor": "Bárbara Sousa*, Francisco J D Marques",
    "ficheiro": "P42.jpg"
  },
  {
    "numero": 43,
    "titulo": "Pedras na Navegação pelo Ducto de Wharton: Sialolitíase Submandibular",
    "autor": "Bruno Mota*; Fernanda Costa; João Abreu; João Lourenço; Andreia Fernandes;",
    "ficheiro": "P43.jpg"
  }
]
