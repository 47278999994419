[
  {
    "numero": 22,
    "titulo": "Atraso da erupção dentária: Revisão da literatura",
    "autor": "Limary Sacramento Lima*, Ana Sofia de Oliveira Bento Roseiro, Sónia Margarida Alves Pereira",
    "ficheiro": "P22.jpg"
  },
  {
    "numero": 23,
    "titulo": "Obtenção de substratos dentinários “patológicos” para estudos laboratoriais: revisão",
    "autor": "Maria Isabel Dantas Viana*, Gabriela Almeida, João Carlos Ramos, Ana Luísa Costa",
    "ficheiro": "P23.jpg"
  },
  {
    "numero": 24,
    "titulo": "Métodos Ortodônticos para a Correção da Curva de Spee: Scoping review",
    "autor": "Ana Lúcia*, Inês Francisco, Catarina Nunes, Raquel Travassos, Carlos Miguel Marto, Francisco do Vale",
    "ficheiro": "P24.jpg"
  },
  {
    "numero": 25,
    "titulo": "Alterações da Cavidade Oral em pacientes com Doença de Biologia dos telómeros - Foco na Disqueratose Congénita (State of Art)",
    "autor": "Inês Margarida Leal Salgado*, Joana Alexandra Teixeira Azevedo, Ana Bela Sarmento Antunes Cruz Ribeiro, Francisco Joaquim Dias Marques",
    "ficheiro": "P25.jpg"
  },
  {
    "numero": 26,
    "titulo": "ALINHADORES VS APARELHO FIXO: VANTAGENS E DESVANTAGENS. SCOPE REVIEW",
    "autor": "César Eulógio Rocha da Silva*; José Romero Souto de Sousa Júnior",
    "ficheiro": "P26.jpg"
  }
]
