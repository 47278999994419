[
  {
    "numero": 1,
    "titulo": "Avaliação do stresse em Doentes com Disfunção Temporomandibular",
    "autor": "Ana Cláudia Gomes Oliveira*, Maria João Pascoal Rodrigues, Bruno Miguel Macedo Sousa, Nansi Lopez-Valverde Hernandez",
    "ficheiro": "P1.jpg"
  },
  {
    "numero": 2,
    "titulo": "Avaliação do risco de cárie através do estudo da microflora oral em doentes com fenda lábio-palatina",
    "autor": "Alícia Lima*, Catarina Nunes, Raquel Travassos, Francisco Vale, Bárbara Oliveiros, Susana Alarico, Carlos Miguel Marto, Eunice Carrilho, Anabela Paula, Inês Francisco",
    "ficheiro": "P2.jpg"
  },
  {
    "numero": 3,
    "titulo": "O efeito do pré-aquecimento de resinas compostas na microinfilitração: um estudo in vitro",
    "autor": "Mariana Frias*, Carlos Miguel Marto, Bárbara Oliveiros, Ana Coelho, Inês Amaro, Ana Margarida Abrantes, Filomena Botelho, Eunice Carrilho, Salomé Pires, Anabela Baptista Paula",
    "ficheiro": "P3.jpg"
  },
  {
    "numero": 4,
    "titulo": "Comparação da Eficácia entre Agulhamento Seco, Compressão Isquémica e Cross Tapes no Masséter",
    "autor": "Margarida Arsénio dos Santos*; Bruno Macedo de Sousa; Maria João Pascoal Rodrigues; David Manuel Teixeira Neves",
    "ficheiro": "P4.jpg"
  },
  {
    "numero": 5,
    "titulo": "Microleakage Assessment Techniques: A Scoping Review And An In Vitro Pilot Study",
    "autor": "Sofia Vieira*, Anabela Baptista Paula, Eunice Carrilho, Ana Coelho, Inês Amaro, Bárbara Oliveiros, Susana Alarico, Ana Margarida Abrantes, Salomé Pires, Filomena Botelho, Carlos Miguel Marto",
    "ficheiro": "P5.jpg"
  },
  {
    "numero": 6,
    "titulo": "Assessment of bone density in patients with impacted maxillary canines: a retrospective study",
    "autor": "Rodrigo Matias*, Raquel Travassos, Catarina Nunes, Inês Francisco, Francisco Caramelo, Francisco Vale",
    "ficheiro": "P6.jpg"
  },
  {
    "numero": 7,
    "titulo": "Libertação de iões de cobalto de ligas metálicas biomédicas de Co-Cr - estudo in vitro",
    "autor": "José António Carvalho Varela*, Prof. Doutor Pedro Nicolau, Prof. Doutora Ana Cristina Ribeiro, Prof. Doutor Artur Valente, Prof. Doutora Sónia Fangaia",
    "ficheiro": "P7.jpg"
  },
  {
    "numero": 8,
    "titulo": "Adesão da zircónia à estrutura dentária usando diferentes protocolos de adesão",
    "autor": "Francisca Oliveira*; Ricardo Dias; Cristiano Alves; João Carlos Ramos; Cláudia Brites",
    "ficheiro": "P8.jpg"
  },
  {
    "numero": 9,
    "titulo": "Influência da expansão maxilar na sintomatologia da apneia obstrutiva do sono",
    "autor": "André Venâncio*, Inês Francisco, Bruno Macedo de Sousa, Catarina Nunes, Raquel Travassos, Francisco do Vale",
    "ficheiro": "P9.jpg"
  },
  {
    "numero": 10,
    "titulo": "Avaliação da citotoxicidade de agentes químicos de remoção de cárie: Estudo in vitro",
    "autor": "Carlos Miguel Marto*, Sara Barreira, Anabela Paula, Inês Amaro, Ana Cristina Gonçalves, Eunice Carrilho, Maria Filomena Botelho, Mafalda Laranjo, Ana Coelho",
    "ficheiro": "P10.jpg"
  },
  {
    "numero": 11,
    "titulo": "Avaliação da prevalência de cárie dentária numa população adulta com obesidade",
    "autor": "Inês Melo*, Inês Amaro, Ana Coelho",
    "ficheiro": "P11.jpg"
  },
  {
    "numero": 12,
    "titulo": "Comportamento óptico de coroas cerâmicas cimentadas sobre substratos escurecidos - estudo in vitro",
    "autor": "Raul Yehudi*; Ricardo Dias; Cristiano Alves",
    "ficheiro": "P12.jpg"
  },
  {
    "numero": 13,
    "titulo": "Efeito de fibras de reforço na resistência à flexão de resinas definitivas impressas: estudo piloto",
    "autor": "Inês Silva*, Francisco Silva, Gabriela Almeida, Bruno Matos, Ricardo Dias, Alexandra Vinagre, João Carlos Ramos",
    "ficheiro": "P13.jpg"
  },
  {
    "numero": 14,
    "titulo": "Avaliação de formas de adesão de dentes de prótese realizados em diferentes materiais a uma estrutura híbrida sobre implantes",
    "autor": "Ricardo Rodrigues*, Ricardo Dias, Cristiano Alves, João Carlos Ramos",
    "ficheiro": "P14.jpg"
  },
  {
    "numero": 15,
    "titulo": "Efeito de fibras de reforço na resistência à flexão de resinas provisórias impressas: estudo piloto",
    "autor": "Francisco Silva*, Inês Silva, Gabriela Almeida, Bruno Matos, Ricardo Dias, Alexandra Vinagre, João Carlos Ramos",
    "ficheiro": "P15.jpg"
  }
]
