[
  {
    "numero": 44,
    "titulo": "Carcinoma Espinocelular: Por Vezes um Desafio Diagnóstico",
    "autor": "Carlos Salgado*, João Abreu, Andreia Fernandes, Inês Santos, José Pedro Figueiredo",
    "ficheiro": "P44.jpg"
  },
  {
    "numero": 45,
    "titulo": "Regeneração de Defeitos Ósseos após a Extração de Terceiro Molar: Caso Clínico com Follow Up de 2 anos",
    "autor": "Inês Morgado*, João Luzio, Joana Saraiva-Amaral, Sérgio Matos, Daniela Alves-Pereira",
    "ficheiro": "P45.jpg"
  },
  {
    "numero": 46,
    "titulo": "Displasia odonto-onico-cutânea - forma rara de displasia ectodérmica: qual o melhor plano terapêutico?",
    "autor": "Andreia Esteves Fernandes*; Laura Nobre Rodrigues; José Pedro Figueiredo; Maria das Dores; Bruno Mota; João Lourenço",
    "ficheiro": "P46.jpg"
  },
  {
    "numero": 47,
    "titulo": "INFEÇÃO POR HERPES ZOSTER NO RAMO MANDIBULAR DO NERVO TRIGÉMIO – A PROPÓSITO DE UM CASO CLÍNICO",
    "autor": "Catarina Norte*, Beatriz Dominguez, José Pedro Figueiredo",
    "ficheiro": "P47.jpg"
  },
  {
    "numero": 48,
    "titulo": "Lesões endo-periodontais: o desafio do diagnóstico",
    "autor": "Daniela Pedrosa*, Elsa Domingues, João Miguel Santos, Isabel Poiares Baptista",
    "ficheiro": "P48.jpg"
  },
  {
    "numero": 49,
    "titulo": "Metástase oral de Carcinoma de Células Renais - a propósito de um caso clínico",
    "autor": "João Gustavo Lourenço*, Vladislav Danu, Olimpia Delgado, Bruno Mota, Andreia Fernandes",
    "ficheiro": "P49.jpg"
  },
  {
    "numero": 50,
    "titulo": "Manifestações orofaciais na síndrome de Ehlers-Danlos: descrição de caso clínico mãe/filha",
    "autor": "Sara Barreira*, Raul Pereira, Pedro Silva, Daniela Silva",
    "ficheiro": "P50.jpg"
  }
]
