[
  {
    "numero": 1,
    "titulo": "Avaliação do stresse em Doentes com Disfunção Temporomandibular",
    "autor": "Ana Cláudia Gomes Oliveira*, Maria João Pascoal Rodrigues, Bruno Miguel Macedo Sousa, Nansi Lopez-Valverde Hernandez",
    "ficheiro": "P1.jpg"
  },
  {
    "numero": 2,
    "titulo": "Avaliação do risco de cárie através do estudo da microflora oral em doentes com fenda lábio-palatina",
    "autor": "Alícia Lima*, Catarina Nunes, Raquel Travassos, Francisco Vale, Bárbara Oliveiros, Susana Alarico, Carlos Miguel Marto, Eunice Carrilho, Anabela Paula, Inês Francisco",
    "ficheiro": "P2.jpg"
  },
  {
    "numero": 3,
    "titulo": "O efeito do pré-aquecimento de resinas compostas na microinfilitração: um estudo in vitro",
    "autor": "Mariana Frias*, Carlos Miguel Marto, Bárbara Oliveiros, Ana Coelho, Inês Amaro, Ana Margarida Abrantes, Filomena Botelho, Eunice Carrilho, Salomé Pires, Anabela Baptista Paula",
    "ficheiro": "P3.jpg"
  },
  {
    "numero": 4,
    "titulo": "Comparação da Eficácia entre Agulhamento Seco, Compressão Isquémica e Cross Tapes no Masséter",
    "autor": "Margarida Arsénio dos Santos*; Bruno Macedo de Sousa; Maria João Pascoal Rodrigues; David Manuel Teixeira Neves",
    "ficheiro": "P4.jpg"
  },
  {
    "numero": 5,
    "titulo": "Microleakage Assessment Techniques: A Scoping Review And An In Vitro Pilot Study",
    "autor": "Sofia Vieira*, Anabela Baptista Paula, Eunice Carrilho, Ana Coelho, Inês Amaro, Bárbara Oliveiros, Susana Alarico, Ana Margarida Abrantes, Salomé Pires, Filomena Botelho, Carlos Miguel Marto",
    "ficheiro": "P5.jpg"
  },
  {
    "numero": 6,
    "titulo": "Assessment of bone density in patients with impacted maxillary canines: a retrospective study",
    "autor": "Rodrigo Matias*, Raquel Travassos, Catarina Nunes, Inês Francisco, Francisco Caramelo, Francisco Vale",
    "ficheiro": "P6.jpg"
  },
  {
    "numero": 7,
    "titulo": "Libertação de iões de cobalto de ligas metálicas biomédicas de Co-Cr - estudo in vitro",
    "autor": "José António Carvalho Varela*, Prof. Doutor Pedro Nicolau, Prof. Doutora Ana Cristina Ribeiro, Prof. Doutor Artur Valente, Prof. Doutora Sónia Fangaia",
    "ficheiro": "P7.jpg"
  },
  {
    "numero": 8,
    "titulo": "Adesão da zircónia à estrutura dentária usando diferentes protocolos de adesão",
    "autor": "Francisca Oliveira*; Ricardo Dias; Cristiano Alves; João Carlos Ramos; Cláudia Brites",
    "ficheiro": "P8.jpg"
  },
  {
    "numero": 9,
    "titulo": "Influência da expansão maxilar na sintomatologia da apneia obstrutiva do sono",
    "autor": "André Venâncio*, Inês Francisco, Bruno Macedo de Sousa, Catarina Nunes, Raquel Travassos, Francisco do Vale",
    "ficheiro": "P9.jpg"
  },
  {
    "numero": 10,
    "titulo": "Avaliação da citotoxicidade de agentes químicos de remoção de cárie: Estudo in vitro",
    "autor": "Carlos Miguel Marto*, Sara Barreira, Anabela Paula, Inês Amaro, Ana Cristina Gonçalves, Eunice Carrilho, Maria Filomena Botelho, Mafalda Laranjo, Ana Coelho",
    "ficheiro": "P10.jpg"
  },
  {
    "numero": 11,
    "titulo": "Avaliação da prevalência de cárie dentária numa população adulta com obesidade",
    "autor": "Inês Melo*, Inês Amaro, Ana Coelho",
    "ficheiro": "P11.jpg"
  },
  {
    "numero": 12,
    "titulo": "Comportamento óptico de coroas cerâmicas cimentadas sobre substratos escurecidos - estudo in vitro",
    "autor": "Raul Yehudi*; Ricardo Dias; Cristiano Alves",
    "ficheiro": "P12.jpg"
  },
  {
    "numero": 13,
    "titulo": "Efeito de fibras de reforço na resistência à flexão de resinas definitivas impressas: estudo piloto",
    "autor": "Inês Silva*, Francisco Silva, Gabriela Almeida, Bruno Matos, Ricardo Dias, Alexandra Vinagre, João Carlos Ramos",
    "ficheiro": "P13.jpg"
  },
  {
    "numero": 14,
    "titulo": "Avaliação de formas de adesão de dentes de prótese realizados em diferentes materiais a uma estrutura híbrida sobre implantes",
    "autor": "Ricardo Rodrigues*, Ricardo Dias, Cristiano Alves, João Carlos Ramos",
    "ficheiro": "P14.jpg"
  },
  {
    "numero": 15,
    "titulo": "Efeito de fibras de reforço na resistência à flexão de resinas provisórias impressas: estudo piloto",
    "autor": "Francisco Silva*, Inês Silva, Gabriela Almeida, Bruno Matos, Ricardo Dias, Alexandra Vinagre, João Carlos Ramos",
    "ficheiro": "P15.jpg"
  },
  {
    "numero": 16,
    "titulo": "Adesão em dentes hipomineralizados: Uma Revisão Sistemática Integrativa",
    "autor": "Françoise Kleinsorgen*, Luis Caetano, Orlanda Torres",
    "ficheiro": "P16.jpg"
  },
  {
    "numero": 17,
    "titulo": "The Impact of Various Treatments on the QoL of Burning Mouth Syndrome Patients: A Systematic Review",
    "autor": "Maria Pereira*, João Mendes Abreu, Anabela Quitério, Érica Cerqueira, Tiago Nunes, José Figueiredo, Ana Corte-Real",
    "ficheiro": "P17.jpg"
  },
  {
    "numero": 19,
    "titulo": "Musicoterapia como técnica de controlo comportamental no paciente pediátrico – revisão da literatura",
    "autor": "Inês Faria*, Ana Daniela Soares, Inês Nunes, Ana Margarida Esteves, Ana Luísa Costa, Maria Teresa Xavier",
    "ficheiro": "P19.jpg"
  },
  {
    "numero": 20,
    "titulo": "Potencial efeito de águas com sabor na erosão dentária – revisão da literatura",
    "autor": "Mariana Branco*, Bárbara Cunha, Inês Nunes, Ana Margarida Esteves, Maria Teresa Xavier, Ana Luísa Costa, Ana Daniela Soares",
    "ficheiro": "P20.jpg"
  },
  {
    "numero": 21,
    "titulo": "Citotoxicidade dos alinhadores ortodônticos: State of art",
    "autor": "Beatriz Ornelas*, Catarina Nunes, Carlos Miguel Marto, Anabela Baptista Paula, Mafalda Laranjo, Raquel Travassos, Inês Francisco, Francisco Vale",
    "ficheiro": "P21.jpg"
  },
  {
    "numero": 22,
    "titulo": "Atraso da erupção dentária: Revisão da literatura",
    "autor": "Limary Sacramento Lima*, Ana Sofia de Oliveira Bento Roseiro, Sónia Margarida Alves Pereira",
    "ficheiro": "P22.jpg"
  },
  {
    "numero": 23,
    "titulo": "Obtenção de substratos dentinários “patológicos” para estudos laboratoriais: revisão",
    "autor": "Maria Isabel Dantas Viana*, Gabriela Almeida, João Carlos Ramos, Ana Luísa Costa",
    "ficheiro": "P23.jpg"
  },
  {
    "numero": 24,
    "titulo": "Métodos Ortodônticos para a Correção da Curva de Spee: Scoping review",
    "autor": "Ana Lúcia*, Inês Francisco, Catarina Nunes, Raquel Travassos, Carlos Miguel Marto, Francisco do Vale",
    "ficheiro": "P24.jpg"
  },
  {
    "numero": 25,
    "titulo": "Alterações da Cavidade Oral em pacientes com Doença de Biologia dos telómeros - Foco na Disqueratose Congénita (State of Art)",
    "autor": "Inês Margarida Leal Salgado*, Joana Alexandra Teixeira Azevedo, Ana Bela Sarmento Antunes Cruz Ribeiro, Francisco Joaquim Dias Marques",
    "ficheiro": "P25.jpg"
  },
  {
    "numero": 26,
    "titulo": "ALINHADORES VS APARELHO FIXO: VANTAGENS E DESVANTAGENS. SCOPE REVIEW",
    "autor": "César Eulógio Rocha da Silva*; José Romero Souto de Sousa Júnior",
    "ficheiro": "P26.jpg"
  },
  {
    "numero": 27,
    "titulo": "Falha primária de erupção dentária e o gene do recetor 1 da hormona da paratiróide",
    "autor": "Joana Pereira*; Sónia A Pereira; Ana Sofia Roseiro",
    "ficheiro": "P27.jpg"
  },
  {
    "numero": 28,
    "titulo": "Effect of endodontic irrigation using sodium hypochlorite on adhesion to coronal dentin – A narrative review",
    "autor": "Elder Fernandes* , Joana A. Marques, Rui Falacho, João Carlos Ramos , Paulo J. Palma",
    "ficheiro": "P28.jpg"
  },
  {
    "numero": 29,
    "titulo": "Comparação de diferentes tipos de cimentos de obturação em Endodontia: Revisão Narrativa.",
    "autor": "Moushir Affaki *, Moaz Alhaddad *, José Martinho, Ana Craveiro , Siri Paulo, Manuel Marques Ferreira",
    "ficheiro": "P29.jpg"
  },
  {
    "numero": 30,
    "titulo": "Relação entre o tratamento ortodôntico com alinhadores e a atividade dos músculos mastigatórios: scoping review",
    "autor": "Joana Francela*, Sónia Alves, Natália Ferreira",
    "ficheiro": "P30.jpg"
  },
  {
    "numero": 31,
    "titulo": "Medicolegal responsibility from the healthcare professional's viewpoint - a scoping review",
    "autor": "Luís, C* ; Ribeiro R , Seoane R , Côrte-Real A",
    "ficheiro": "P31.jpg"
  },
  {
    "numero": 32,
    "titulo": "NÍVEL DE LITERACIA E CONHECIMENTOS EM SAÚDE ORAL DOS PAIS E FATORES ASSOCIADOS: REVISÃO NARRATIVA",
    "autor": "Beatriz Tomé*, Margarida Esteves, Inês Nunes, Bárbara Cunha, Ana Daniela Soares, Sara Rosa, Maria Teresa Xavier, Ana Luísa Costa",
    "ficheiro": "P32.jpg"
  },
  {
    "numero": 33,
    "titulo": "Associação entre Periodontite e Doença de Alzheimer: revisão crítica da literatura",
    "autor": "Pedro Silva*, Miguel Pereira, Orlando Martins, Isabel Baptista",
    "ficheiro": "P33.jpg"
  },
  {
    "numero": 34,
    "titulo": "Título: Mordida Cruzada Posterior em Idade Precoce: Diagnóstico e Tratamento",
    "autor": "Maria Luís Oliveira*, Eduardo Seabra, Francisco Silva, Paula Bebiano, Ana Roseiro, Sónia Alves",
    "ficheiro": "P34.jpg"
  },
  {
    "numero": 35,
    "titulo": "Literacia em saúde oral na gravidez e suas implicações: revisão narrativa",
    "autor": "Inês Bolhaqueiro*, Margarida Esteves, Inês Nunes, Ana Daniela Soares, Maria Teresa Xavier, Bárbara Cunha, Sara Rosa, Ana Luísa Costa",
    "ficheiro": "P35.jpg"
  },
  {
    "numero": 36,
    "titulo": "Abordagem terapêutica de lesões periapicais extensas de origem endodôntica – Revisão bibliográfica",
    "autor": "Mariana Oliveira*, Joana A. Marques , Catarina Chaves, Francisco J. D. Marques, Paulo J. Palma",
    "ficheiro": "P36.jpg"
  },
  {
    "numero": 37,
    "titulo": "Injeção intra-articular de Ácido Hialurónico e Plasma Rico em Plaquetas: Caso Clínico",
    "autor": "Mariana Marques Dias*, Bruno Miguel Macedo Sousa, Maria João Pascoal Rodrigues, Nansi Lopez-Valverde Hernandez",
    "ficheiro": "P37.jpg"
  },
  {
    "numero": 38,
    "titulo": "Potencial de cicatrização precoce da técnica MIST com plugs de L-PRF na regeneração periodontal",
    "autor": "Miriam Gomez Aboín*; Sérgio Matos",
    "ficheiro": "P38.jpg"
  },
  {
    "numero": 39,
    "titulo": "Enfisema facial após uso de jato de ar-água-pó na instrumentação subgengival: relato de caso clínico",
    "autor": "Bruno Matos*, João Carlos Ramos, Sérgio Matos",
    "ficheiro": "P39.jpg"
  },
  {
    "numero": 40,
    "titulo": "Complicações graves da utilização de bioestimuladores injetáveis: a propósito de um caso clínico",
    "autor": "Dmitry Shelepenko*; Beatriz Jardim; Bárbara Sousa; Francisco Marques",
    "ficheiro": "P40.jpg"
  },
  {
    "numero": 41,
    "titulo": "PIOESTOMATITE VEGETANTE: UM DESAFIO DIAGNÓSTICO",
    "autor": "Lara Gomes*, Sofia Vieira, Mariana Branco, Orlando Martins, Francisco Marques",
    "ficheiro": "P41.jpg"
  },
  {
    "numero": 42,
    "titulo": "Histiocitose de Células não Langerhans: a importância da abordagem multidisciplinar",
    "autor": "Bárbara Sousa*, Francisco J D Marques",
    "ficheiro": "P42.jpg"
  },
  {
    "numero": 43,
    "titulo": "Pedras na Navegação pelo Ducto de Wharton: Sialolitíase Submandibular",
    "autor": "Bruno Mota*; Fernanda Costa; João Abreu; João Lourenço; Andreia Fernandes;",
    "ficheiro": "P43.jpg"
  },
  {
    "numero": 44,
    "titulo": "Carcinoma Espinocelular: Por Vezes um Desafio Diagnóstico",
    "autor": "Carlos Salgado*, João Abreu, Andreia Fernandes, Inês Santos, José Pedro Figueiredo",
    "ficheiro": "P44.jpg"
  },
  {
    "numero": 45,
    "titulo": "Regeneração de Defeitos Ósseos após a Extração de Terceiro Molar: Caso Clínico com Follow Up de 2 anos",
    "autor": "Inês Morgado*, João Luzio, Joana Saraiva-Amaral, Sérgio Matos, Daniela Alves-Pereira",
    "ficheiro": "P45.jpg"
  },
  {
    "numero": 46,
    "titulo": "Displasia odonto-onico-cutânea - forma rara de displasia ectodérmica: qual o melhor plano terapêutico?",
    "autor": "Andreia Esteves Fernandes*; Laura Nobre Rodrigues; José Pedro Figueiredo; Maria das Dores; Bruno Mota; João Lourenço",
    "ficheiro": "P46.jpg"
  },
  {
    "numero": 47,
    "titulo": "INFEÇÃO POR HERPES ZOSTER NO RAMO MANDIBULAR DO NERVO TRIGÉMIO – A PROPÓSITO DE UM CASO CLÍNICO",
    "autor": "Catarina Norte*, Beatriz Dominguez, José Pedro Figueiredo",
    "ficheiro": "P47.jpg"
  },
  {
    "numero": 48,
    "titulo": "Lesões endo-periodontais: o desafio do diagnóstico",
    "autor": "Daniela Pedrosa*, Elsa Domingues, João Miguel Santos, Isabel Poiares Baptista",
    "ficheiro": "P48.jpg"
  },
  {
    "numero": 49,
    "titulo": "Metástase oral de Carcinoma de Células Renais - a propósito de um caso clínico",
    "autor": "João Gustavo Lourenço*, Vladislav Danu, Olimpia Delgado, Bruno Mota, Andreia Fernandes",
    "ficheiro": "P49.jpg"
  },
  {
    "numero": 50,
    "titulo": "Manifestações orofaciais na síndrome de Ehlers-Danlos: descrição de caso clínico mãe/filha",
    "autor": "Sara Barreira*, Raul Pereira, Pedro Silva, Daniela Silva",
    "ficheiro": "P50.jpg"
  }
]
