[
  {
    "numero": 16,
    "titulo": "Adesão em dentes hipomineralizados: Uma Revisão Sistemática Integrativa",
    "autor": "Françoise Kleinsorgen*, Luis Caetano, Orlanda Torres",
    "ficheiro": "P16.jpg"
  },
  {
    "numero": 17,
    "titulo": "The Impact of Various Treatments on the QoL of Burning Mouth Syndrome Patients: A Systematic Review",
    "autor": "Maria Pereira*, João Mendes Abreu, Anabela Quitério, Érica Cerqueira, Tiago Nunes, José Figueiredo, Ana Corte-Real",
    "ficheiro": "P17.jpg"
  },
  {
    "numero": 19,
    "titulo": "Musicoterapia como técnica de controlo comportamental no paciente pediátrico – revisão da literatura",
    "autor": "Inês Faria*, Ana Daniela Soares, Inês Nunes, Ana Margarida Esteves, Ana Luísa Costa, Maria Teresa Xavier",
    "ficheiro": "P19.jpg"
  },
  {
    "numero": 20,
    "titulo": "Potencial efeito de águas com sabor na erosão dentária – revisão da literatura",
    "autor": "Mariana Branco*, Bárbara Cunha, Inês Nunes, Ana Margarida Esteves, Maria Teresa Xavier, Ana Luísa Costa, Ana Daniela Soares",
    "ficheiro": "P20.jpg"
  },
  {
    "numero": 21,
    "titulo": "Citotoxicidade dos alinhadores ortodônticos: State of art",
    "autor": "Beatriz Ornelas*, Catarina Nunes, Carlos Miguel Marto, Anabela Baptista Paula, Mafalda Laranjo, Raquel Travassos, Inês Francisco, Francisco Vale",
    "ficheiro": "P21.jpg"
  }
]
